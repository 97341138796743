<template>
  <div class="dxby-setting-container">
    <h3>考勤配置</h3>
    <div class="dxby-setting-table">
      <ul
        class="dxby-setting-table-ul"
        v-for="(item, key) in settingListData"
        :key="key"
      >
        <li>{{key+1}}</li>
        <li>{{item.name}}</li>
        <li>{{item.value}}</li>
        <li>{{item.unit}}</li>
        <li>
          <el-date-picker
            v-model="item.modelValue"
            type="date"
            placeholder="请输入日期"
            value-format="YYYY-MM-DD"
            format="YYYY-MM-DD"
            v-if="item.unit === '日期'"
            @change="changeValue(item, key)"
          ></el-date-picker>
          <el-input
            v-model="item.modelValue"
            width="220"
            class="dxby-setting-amount"
            size="large"
            placeholder="输入金额"
            @input="changeValue(item, key)"
            refs=""
            v-else
          ></el-input>
        </li>
      </ul>
    </div>
    <div style="height: 120px"></div>
    <div
      class="dxby-setting-smt"
      @click="updateSmt"
    >
      <ElButton type="primary">确定修改</ElButton>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { requestGet, requestPost } from '@/request'
import { SettingTable, updateSettingTable } from '@/request/apiSuffix'
import { ElDatePicker, ElInput, ElButton } from 'element-plus'
import Common from '@/assets/js/common'

export default {
  name: 'tableSetting',
  setup () {
    const settingListData = ref([])
    const smtValue = ref([])
    // const disabledDate = time => time.getTime() < Date.now() - 3600 * 1000 * 24 // 禁用今天以前的日期

    // Mounted
    onMounted(() => getListData())

    // methods
    // 获取到列表数据
    const getListData = async () => {
      const res = await requestGet(SettingTable)
      settingListData.value = await Common.deepClone(res.data)
      settingListData.value.forEach(item => {
        item.modelValue = ''
        smtValue.value.push({ id: item.id, value: item.value })
      })
    }
    // 修改页面金额
    const changeValue = ({ id, modelValue }, key) => {
      smtValue.value[key].id = id
      smtValue.value[key].value = modelValue
    }
    // 确定修改
    const updateSmt = async () => {
      console.log(smtValue.value)
      const res = await requestPost(updateSettingTable, {
        settings: smtValue.value
      })
      const { code, msg } = res.data
      if (code === 200) {
        smtValue.value.forEach(item => {
          item.value = ''
        })
        smtValue.value = []
        getListData()
      }
      if (code === 500) alert(msg)
    }
    return {
      settingListData,
      // disabledDate,
      changeValue,
      updateSmt
    }
  },
  components: {
    ElDatePicker,
    ElInput,
    ElButton
  }
}
</script>

<style scoped lang="less">
.otx (@num) {
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: @num;
  -webkit-box-orient: vertical;
}
.dxby-setting-container {
  width: 1000px;
  margin: 0 auto;
  overflow: hidden;
  h3 {
    line-height: 60px;
    text-align: center;
    display: block;
    color: #333;
    font-size: 16px;
  }
  .dxby-setting-table {
    .dxby-setting-table-ul {
      display: flex;
      li {
        flex: 2;
        border-left: 1px solid #eee;
        border-top: 1px solid #eee;
        text-align: center;

        padding: 20px 0;
      }
      li:first-child {
        flex: 1;
        border-left: 0;
      }
      li:last-child {
        flex: 3;
        border-right: 0;
        cursor: pointer;
        color: blue;
      }
    }
  }
}
.dxby-setting-amount {
  width: 80%;
  display: block;
  margin: 0 auto;
}
.dxby-setting-smt {
  width: 100%;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 30px 0;
  background-color: #fff;
}
</style>
